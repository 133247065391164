import './App.css';

import { ethers } from 'ethers'
import { useState, useEffect } from 'react'

import twitter from './assets/images/twitter.png'
import solid from './assets/images/solid.gif'
import triple1 from './assets/images/triple1.png'
import triple2 from './assets/images/triple2.png'
import triple3 from './assets/images/triple3.png'
import triple4 from './assets/images/triple4.png'
import opp2x21 from './assets/images/opp2x2_1.png'
import opp2x22 from './assets/images/opp2x2_2.png'
import opp1 from './assets/images/opp_1.png'
import opp2 from './assets/images/opp_2.png'
import n2x21 from './assets/images/2x2_1.png'
import n2x22 from './assets/images/2x2_2.png'
import n2x23 from './assets/images/2x2_3.png'
import n2x24 from './assets/images/2x2_4.png'
import near1 from './assets/images/near1.png'
import near2 from './assets/images/near2.png'
import near3 from './assets/images/near3.png'
import near4 from './assets/images/near4.png'
import colorful from './assets/images/colorful.gif'
import opensea from './assets/images/opensea.png'
import NinePLogo from './assets/images/9P.gif'
import etherscan from './assets/images/etherscan.webp'

import { Alchemy, Network } from 'alchemy-sdk';

import {
  targetChain,
  NinePContract,
  alchemyApi
} from './assets/config'

import NineP from './assets/ninep.json'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
//import 'react-accessible-accordion/dist/fancy-example.css';

const portraitMediaQuery = window.matchMedia('(orientation: portrait)');
const landscapeMediaQuery = window.matchMedia('(orientation: landscape)');

function handlePortraitOrientationChange(event) {
  if (event.matches) {
    window.location.reload(true);
  }
}

function handleLandscapeOrientationChange(event) {
  if (event.matches) {
    window.location.reload(true);
  }
}

portraitMediaQuery.addListener(handlePortraitOrientationChange);
landscapeMediaQuery.addListener(handleLandscapeOrientationChange);

function App() {

  const [auctionCounter, setauctionCounter] = useState([])
  const [maxSupply, setmaxSupply] = useState([])
  const [treasury, setTreasury] = useState([])
  const [openAuctions, setopenAuctions] = useState([])
  const [tokenCounter, settokenCounter] = useState([])
  const [closedAuctions, setclosedAuctions] = useState([])
  const [currentBlock, setcurrentBlock] = useState([])

  useEffect(() => {
    // call api or anything
    loadData()
    
 }, []);

  async function connect() {
    try {
      const provider = new ethers.providers.Web3Provider(
        window.ethereum,
        "any"
      );

      provider.on("network", (newNetwork, oldNetwork) => {
        // When a Provider makes its initial connection, it emits a "network"
        // event with a null oldNetwork along with the newNetwork. So, if the
        // oldNetwork exists, it represents a changing network
        if (oldNetwork) {
            window.location.reload();
        }
      }); 
      
      await provider.send("eth_requestAccounts", [])
      const signer = provider.getSigner()

      const address = await signer.getAddress() 

      let addrToPrint = address.substr(0,4) +"..." + address.substr(38,42)

      let chainId = await provider.getNetwork();
      chainId = chainId['chainId'];
      if(chainId != targetChain) {
        document.getElementById("connect").innerText = "Wrong network!";
      } else {
        document.getElementById("connect").innerHTML = addrToPrint;    
        loadData()
        document.getElementById("auctions").classList.remove("hidden")
      }
    } catch(e) {
      
    }
  }

  async function loadData() {
    /*
    const settings = {
      apiKey: alchemyApi, // Replace with your Alchemy API key.
      network: Network.ETH_MAINNET // Replace with your network.
    };
    const alchemy = new Alchemy(settings);

    
    const provider = await alchemy.config.getProvider();
    const signer = provider.getSigner()
    const contract = new ethers.Contract(NinePContract, NineP.abi, provider)
    */
    
    const provider = new ethers.providers.Web3Provider(
      window.ethereum,
      "any"
    );

    await provider.send("eth_requestAccounts", [])
    const signer = provider.getSigner()

    const address = await signer.getAddress() 

    const contract = new ethers.Contract(NinePContract, NineP.abi, signer)
    

    let auctionCounter = await contract.auctionCounter()
    setauctionCounter(auctionCounter.toString())
    let maxSupply = await contract.maxSupply()
    setmaxSupply(maxSupply.toString())
    let tokenCounter = await contract.tokenCounter()
    settokenCounter(tokenCounter.toString())
    let balance = await provider.getBalance(NinePContract);
    setTreasury(ethers.utils.formatUnits(balance.toString().toString(), 'ether').substring(0,5))
    let auctionsBlocks = Number(await contract.auctionBlockDuration)
    let currentBlock = await provider.getBlockNumber()
    let currentTime = await provider.getBlock(currentBlock)
    currentTime = currentTime.timestamp
    setcurrentBlock(currentBlock) 

    let auctionDuration = Number(await contract.auctionSecondsDuration());

    let openAuctions = await contract.getOpenAuctions()
    const openauctions = await Promise.all(openAuctions.map(async i => {
      let auctionId = i.toNumber()
      console.log(auctionId)
      let auction = await contract.auctions(auctionId)

      let endingDay = auction[0]
      let state

      if(Number(endingDay) >= Math.trunc(Number(currentTime / auctionDuration))) {
        state = true
      } else {
        state = false
      }

      let item

      item = {
        auctionId: auctionId,
        start: Number(auction.startingBlock),
        end: Number(auction.endingBlock),
        bid: ethers.utils.formatUnits(auction.currentBid.toString().toString(), 'ether')+" ETH",
        bider: auction.currentBider.substr(0,4) +"..." + auction.currentBider.substr(38,42),
        biderURL: "https://etherscan.io/address/" + auction.currentBider,
        open: auction.resolved,
        nft: auction.winnerNFT,
        state: state,
        btnId: "bid"+auctionId,
        btn2Id: "bidBtn"+auctionId
      }

      return item
      
    }))
    
    setopenAuctions(openauctions)

    let closedAuctions = await contract.getClosedAuctions()
    const closedauctions = await Promise.all(closedAuctions.map(async i => {
      let auctionId = i.toNumber()

      let auction = await contract.auctions(auctionId)
      let nftimageSmall = await contract.tokenURISmall(auction.winnerNFT)
      nftimageSmall = nftimageSmall.toString().split("data:application/json;base64,")[1]
      nftimageSmall = atob(nftimageSmall)
      let data2 = JSON.parse(nftimageSmall)
      let item

      item = {
        auctionId: auctionId,
        start: Number(auction.startingBlock),
        end: Number(auction.endingBlock),
        bid: ethers.utils.formatUnits(auction.currentBid.toString().toString(), 'ether')+" ETH",
        bider: auction.currentBider.substr(0,4) +"..." + auction.currentBider.substr(38,42),
        biderURL: "https://etherscan.io/address/" + auction.currentBider,
        open: auction.resolved,
        nft: auction.winnerNFT.toString(),
        nftimageSmall: data2.image_data
      }

      return item
      
    }))

    closedauctions.sort(function(a, b) {return b.auctionId - a.auctionId})
    
    setclosedAuctions(closedauctions)
      
  }

  async function closeAuction(auctionId) {
    const provider = new ethers.providers.Web3Provider(
      window.ethereum,
      "any"
    );

    await provider.send("eth_requestAccounts", [])
    const signer = provider.getSigner()

    const address = await signer.getAddress() 

    const contract = new ethers.Contract(NinePContract, NineP.abi, signer)

    let btnId = "bid"+auctionId
    let bidbtn = "bidBtn"+auctionId 

    try {
      const transaction = await contract.closeAuction(auctionId)
      document.getElementById(bidbtn).innerText = "..."
      await transaction.wait()
      document.getElementById(bidbtn).innerText = "Closed"
      document.getElementById(bidbtn).disabled = true
      window.location.reload()
      } catch(e) {
          console.log(e)
      }
    
  }

  async function bid(auctionId) {
    const provider = new ethers.providers.Web3Provider(
      window.ethereum,
      "any"
    );

    await provider.send("eth_requestAccounts", [])
    const signer = provider.getSigner()

    const address = await signer.getAddress() 

    const contract = new ethers.Contract(NinePContract, NineP.abi, signer)
    let btnId = "bid"+auctionId
    let bidbtn = "bidBtn"+auctionId
    let bid = document.getElementById(btnId).value
    const finalPay = ethers.utils.parseEther(bid)

    let auction = ethers.BigNumber.from(auctionId)

    try {
      const transaction = await contract.bid( finalPay, auction, {
        value: finalPay
      })
      document.getElementById(bidbtn).innerText = "..."
      await transaction.wait()
      document.getElementById(bidbtn).innerText = "Bid"
      window.location.reload()
      } catch(e) {
          console.log(e)
      }
    
  }

  async function startAuction() {
    document.getElementById("auctionStartError").classList.add("hidden")

    const provider = new ethers.providers.Web3Provider(
      window.ethereum,
      "any"
    );

    await provider.send("eth_requestAccounts", [])
    const signer = provider.getSigner()

    const address = await signer.getAddress() 

    const contract = new ethers.Contract(NinePContract, NineP.abi, signer)

    let bid = document.getElementById("startingBid").value
    let finalPay = ethers.utils.parseUnits(bid, 18)

    try {
      const transaction = await contract.createAuction( {
        value: finalPay
      })
      document.getElementById("auctionStartBtn").classList.remove("bg-black")
      document.getElementById("auctionStartBtn").classList.add("bg-red-500")
      await transaction.wait()
      document.getElementById("auctionStartBtn").classList.add("bg-black")
      document.getElementById("auctionStartBtn").classList.remove("bg-red-500")
      window.location.reload()
      } catch(e) {
          document.getElementById("auctionStartError").classList.remove("hidden")
          console.log(e)
      }
    
  }

  if(window.innerWidth >= 420) {
  return (
    <div className="App menlo mx-auto text-center large" id="app">
      <div id="top">
        <div className="grid grid-cols-13" id="header">
          <div className="col-span-1">
            {/*<img src={NinePLogo} alt="NineP Logo"></img>*/}
          </div>
          <div className="col-span-10">
            <h1 className="text-left pgreen headerTitle ">9ineP</h1>
          </div>
          <div className="col-span-2">
            <button onClick={connect} className="dina text-white hover:text-black connectBtn" id="connect">connect wallet</button>
          </div>
        </div>
        <div className="grid grid-cols-13 ">
          <div className="col-span-5">
            <div className=" igreen text-left">
              <h2 className="subTitle text-left" id="leadtitle">10K quantum flowers on-chain</h2>
              <p className="subText">1 day - 1 auction -</p>
              <p className="subText">1 random generation</p>
            </div>
            <div className="col-span-3 grid grid-cols-5 ">
              <div className="col-span-1 row-span-1 mx-auto">
                <a href="https://twitter.com/9ine_p" target="blank" rel="noreferrer"><img className="opacity-0 inline icon transform hover:scale-110" src={twitter}></img></a>
              </div>
              <div className="col-span-1 row-span-1">
                <a href="https://opensea.io/collection/9inep" target="blank" rel="noreferrer"><img className="opacity-0 inline icon transform hover:scale-110" src={opensea} ></img></a>
              </div>
              <div className="col-span-1 row-span-1">
                <a href="https://etherscan.io/address/0x98c2a2aAe05B62f7bFc286E0963eD280ef0Df748" target="blank" rel="noreferrer"><img className="opacity-0 inline icon transform hover:scale-110" src={etherscan} ></img></a>
              </div>
              <div className="col-span-2">
                <p className="counter igrey">{tokenCounter}</p>
              </div>
            </div>
            <div className="grid grid-cols-5">
              <div className="col-span-3">

              </div>
              <div className="col-span-2 mt-14">
                <p className="treasury igrey">{treasury}</p>
              </div>
            </div>
          </div>
          <div className="col-span-8">
            <div className="dina text-white tableDiv">
              <h2 className="tableTitle">Open Auctions</h2>
              {
                    (openAuctions.length === 0 ? (
                      <p>No Auctions open currently</p>
                      
                    ):(
                
                        <table className="w-full mx-auto table-fixed">
                          <tr className="tableHeader">
                            <td>#</td>
                            <td>CRT Bid</td>
                            <td>CRT LDR</td>
                            <td></td>
                            <td></td>
                          </tr>
                        
                        <tbody className="w-3/4 mx-auto">
                          {
                            openAuctions.map((prop, i) => (
                              prop.state === true ? (
                              <tr className="w-3/4 mx-auto tableRow" key={i}>
                                <td>{prop.auctionId}</td>
                                <td>{prop.bid}</td>
                                <td className="underline"><a href={prop.biderURL} target="_blank" rel="noreferrer">{prop.bider}</a></td>
                                <td ><input className="actInput rounded border boder-black border-solid text-black text-center" id={prop.btnId} placeholder="Bid"></input></td>
                                <td><button id={prop.btn2Id} className="actBtn rounded bg-green-500 text-black hover:bg-green-300" onClick={() => bid(prop.auctionId)}>Bid</button></td>
                              </tr>
                              ):(
                              <tr className="w-3/4 mx-auto tableRow" key={i}>
                                <td>{prop.auctionId}</td>
                                <td>{prop.bid}</td>
                                <td className="underline"><a href={prop.biderURL} target="_blank" rel="noreferrer">{prop.bider}</a></td>
                                <td></td>
                                <td><button id={prop.btnId} className="actBtn rounded bg-green-500 text-black hover:bg-green-300" onClick={() => closeAuction(prop.auctionId)}>Close</button></td>
                              </tr>
                              )
                            ))
                          }
                        </tbody>
                        </table>
                    ))
                  }
            </div>
            <div className="grid grid-cols-8">
              <div className="col-span-3 row-span-3 ">
                <img src={NinePLogo} alt="NineP Logo" className="mx-auto align-middle logo"></img>
              </div>
              <div className="col-span-2 row-span-1 ">
                <input className="rounded border boder-black border-solid text-black text-center text-black auctionInput" id="startingBid" placeholder="0.015"></input>
              </div>
              <div className="col-span-3 row-span-3 ">
                <p className="text-left igreen subText subText2">To enable displays - connect wallet. Only one Auction a day can be created by everyone from 00:00:01 UTC & runs up until 23:59:59 UTC. Winner gets NFT. More info in "Auctions"</p>
                <br></br>
                <p className="subText text-base text-red-500 hidden" id="auctionStartError">auction creation failed</p>
              </div>
              <div className="col-span-2 row-span-2">
                <button className="opacity-0 auctionBtn border-black border rounded-full bg-white bg-black hover:bg-white " id="auctionStartBtn" onClick={startAuction}></button>
              </div>
            </div>
            <div className="overflow-y-scroll dina " id="closed">
              <h2 className=" text-center pt-2 tableTitle table2">Closed Auctions</h2>
              {
                    (closedAuctions.length === 0 ? (
                      <p>No Auctions closed yet</p>
                    ):(
                        <table className="w-3/4 mx-auto table-fixed">
                          <tr className='text-center tableHeader'>
                            <td >#</td>
                            <td>win bid</td>
                            <td>winner</td>
                            <td>NFT</td>
                            <td>id</td>
                          </tr>
                        <tbody className="w-3/4 mx-auto">
                          {
                            closedAuctions.map((prop, i) => (
                              <tr className="w-3/4 mx-auto tableRow" key={i}>
                                <td>{prop.auctionId}</td>
                                <td>{prop.bid}</td>
                                <td className="underline"><a href={prop.biderURL} target="_blank" rel="noreferrer">{prop.bider}</a></td>
                                <td className="mx-auto text-center"><div dangerouslySetInnerHTML={{ __html: prop.nftimageSmall}} className="mx-auto text-center pt-2"></div></td>
                                <td className="mx-auto">{prop.nft}</td>
                                {/*<td><img src={`data:image/svg+xml;utf8,${prop.nftimage}`} width={50} height={50} className="rounded" alt={ prop.auctionId }/></td>
                                */}
                              </tr>
                            ))
                          }
                        </tbody>
                        </table>
                    ))
                }
            </div>
          </div>
        </div>
      </div>
      <div className="w-1300 h-500 text-left" id="rarities">
        <h4 className="compTitle text-left text-white">Rarity and Colors</h4>
        <div className="text-center">
          
          <div className="grid grid-cols-8">
            <div className="col-span-1 relative">
                <p className="absolute bottom-3 left-6 text-white">QTY</p>
            </div>
            <div className=" col-span-7">
              <div className="grid grid-cols-7">
                <h3 className="colorTitle text-white">Solid</h3>
                <h3 className="colorTitle text-white">Triple</h3>
                <h3 className="colorTitle col-span-4 text-white">Double</h3>
                <h3 className="colorTitle text-white">Colorful</h3>
              </div>
              <div className="grid grid-cols-7">
                <h3 className="colorSubTitle"></h3>
                <h3 className="colorSubTitle"></h3>
                <h3 className="colorSubTitle text-white">opp. 2x2</h3>
                <h3 className="colorSubTitle text-white">near 2x2</h3>
                <h3 className="colorSubTitle text-white">opp.</h3>
                <h3 className="colorSubTitle text-white">near</h3>
                <h3 className="colorSubTitle"></h3>
              </div>
               <div className="grid grid-cols-7">
                <div className="">
                  <img src={solid} className="p-1-3" alt="NineP Logo"></img>
                  </div>
                <div className="">
                  <img src={triple1} className="p-1-3" alt="NineP Logo"></img>
                  <img src={triple2} className="p-1-3" alt="NineP Logo"></img>
                  <img src={triple3} className="p-1-3" alt="NineP Logo"></img>
                  <img src={triple4} className="p-1-3" alt="NineP Logo"></img>
                </div>
                <div className="">
                  <img src={opp2x21} className="p-1-3" alt="NineP Logo"></img>
                  <img src={opp2x22} className="p-1-3" alt="NineP Logo"></img>
                </div>
                <div className="">
                  <img src={n2x21} className="p-1-3" alt="NineP Logo"></img>
                  <img src={n2x22} className="p-1-3" alt="NineP Logo"></img>
                  <img src={n2x23} className="p-1-3" alt="NineP Logo"></img>
                  <img src={n2x24} className="p-1-3" alt="NineP Logo"></img>
                </div>
                <div className="">
                  <img src={opp1} className="p-1-3" alt="NineP Logo"></img>
                  <img src={opp2} className="p-1-3" alt="NineP Logo"></img>
                </div>
                <div className="">
                  <img src={near1} className="p-1-3" alt="NineP Logo"></img>
                  <img src={near2} className="p-1-3" alt="NineP Logo"></img>
                  <img src={near3} className="p-1-3" alt="NineP Logo"></img>
                  <img src={near4} className="p-1-3" alt="NineP Logo"></img>
                </div>
                <div className="">
                  <img src={colorful} className="p-1-3" alt="NineP Logo"></img>
                </div>
              </div>
              <div className="grid grid-cols-7 pb-3">
                <h3 className="colorSubTitle text-white">10</h3>
                <h3 className="colorSubTitle text-white">360</h3>
                <h3 className="colorSubTitle text-white">180</h3>
                <h3 className="colorSubTitle text-white">360</h3>
                <h3 className="colorSubTitle text-white">1450</h3>
                <h3 className="colorSubTitle text-white">3270</h3>
                <h3 className="colorSubTitle text-white">4370</h3>
              </div>
            </div>
          </div>
          














          </div>

      </div>
      <div className="text-left">
      <Accordion allowZeroExpanded className="text-left w-9/10">
                <AccordionItem className="AccordionItem">
                    <AccordionItemHeading className="">
                        <AccordionItemButton>
                            <h4 className="compTitle">What is 9ineP</h4>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="">
                        <p className='compText'>
                        "9ineP" means "nine pixels" (3x3) - the tiniest original size of the flowers, so they are truly quantum units! Just 9 pixels, but enough to create something aesthetic, fundamental and jewel-like - that's why the logo looks like a jewelry box's bow.
                        "9P" - conceptual collection of 100% on-chain art, essentially limited to 10k possible variations, stored and randomly generating day by day directly on the Ethereum blockchain.<br></br><br></br>
                         Only one unique flower mints a day by the Auction, and no one knows what. So it will take around 27 years to reveal the entire collection!<br></br><br></br>
                         Each flower has permanent gold core and 9P green leaves. Petals are randomly generating every Auction (the contract uses hashing mechanism for randomness) 
                         from 10 juicy colors: red, orange, yellow, green, diamond, azure, blue, violet, magenta and pink.<br></br><br></br>
                         What flower will bloom today?<br></br>
                         What super rare flowers will appear this year?
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="AccordionItem">
                    <AccordionItemHeading className="">
                        <AccordionItemButton>
                            <h4 className="compTitle">Auctions</h4>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="">
                        <p className='compText'>
                          Who will give it a bloom today?<br></br>
                          To enable displays and enjoy the full experience - connect wallet.<br></br><br></br>
                          Only one Auction can be created a day from 00:00:01 UTC and lasts until 23:59:59 UTC. 
                          Auction is in your hands - everyone can open it with a starting bid 0.015 ETH or more. Auction Winner gets the right to randomly generate flower on-chain, mint it & reveal to the world.<br></br><br></br>
                          When the Auction time runs up - close Auction button appears (this is also a sign that a new one Auction can be created). 
                          This button generates and mints NFT for the highest bidder only! (all the processes on-chain! so switch wallet gas settings on the high to complete mint surely). Flower reveals after mint.<br></br><br></br>
                          Only 12 flowers can be randomly generated, minted and revealed beyond the Auctions: 9 will be gifted to the chosen NFT communities, 2 for the team, and 1 will be raffled on our twitter account - stay tuned! 
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="AccordionItem">
                    <AccordionItemHeading className="">
                        <AccordionItemButton>
                            <h4 className="compTitle">Treasury & Lottery</h4>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="">
                        <p className='compText'>
                         85% of the Auction proceeds goes to the treasury, 15% to the team. 
                         Treasury will be spent on the project's growth and popularity, creating aesthetic jewelry and merch, collaborating with art galleries and brands, supporting charity initiatives to restore our planet, supporting innovative technologies and talented artists..
                         all ideas and intentions will be transparent and co-creative!<br></br><br></br>
                         Also 9P flower = guaranteed pass to the Annual Lottery among the holders & your chance to win a piece of treasury. More flowers - more chances! 
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="AccordionItem">
                    <AccordionItemHeading className="">
                        <AccordionItemButton>
                            <h4 className="compTitle">Kaikasekai</h4>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="">
                        <p className='compText'>
                          "Kaikasekai" means "blooming world."<br></br><br></br>
                          This fundamental principle inspired us to create something beautiful with special mechanics to expand it. 
                          So every day, the quantum entanglement turns into 9P flower, like a symbol of "kaikasekai," to bring you flourishing and fun both in web2 and web3!
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
      </div>
      <div className="">
        &copy; 2022 kaikasekai<br></br>
      </div>

    </div>
  );
  } else {
    return(
      <div className="App menlo mx-auto text-center large" id="app">
      <div id="topMobile">
        <div className="g" id="header">
            <h1 className="text-left pgreen headerTitle" >9ineP</h1>
        </div>
        <div className="grid grid-cols-2">
          <div className="igreen text-left">
            <h2 className="subTitle">10K quantum flowers onchain</h2>
            <p className="subText">1 day - 1 auction -</p>
            <p className="subText">1 random generation</p>
          </div>
          <div className="">
            <img src={NinePLogo} alt="NineP Logo" className="mx-auto align-middle logo"></img>
          </div>
        </div>
        
        <div className="grid grid-cols-2">
          <div className=""> 
            <div className="grid grid-cols-2">
                <div className="">
                  <p className="counter igrey">{tokenCounter}...</p>
                </div>
                <div className="">
                  <p className="treasury igrey">{treasury}...</p>
                </div>
            </div>
            <div className="grid grid-cols-3" id="socials">
              <div className="col-span-1 row-span-1 mx-auto" id="twitter">
                <a href="https://twitter.com/9ine_p" target="blank" rel="noreferrer"><img className="opacity-0 inline icon  transform hover:scale-110" src={twitter}></img></a>
              </div>
              <div className="col-span-1 row-span-1">
                <a href="https://opensea.io/collection/9inep" target="blank" rel="noreferrer"><img className="opacity-0 inline icon  transform hover:scale-110" src={opensea} ></img></a>
              </div>
              <div className="col-span-1 row-span-1">
                <a href="https://etherscan.io/address/0x98c2a2aAe05B62f7bFc286E0963eD280ef0Df748" target="blank" rel="noreferrer" id="ether"><img className="opacity-0 inline icon transform hover:scale-110" src={etherscan} ></img></a>
              </div>
            </div>
          </div>
          <div className="">
            <p className="text-left igreen subText subText2"><br></br>To enable displays - connect wallet. Only one Auction a day can be created by everyone from 00:00:01 UTC & runs up until 23:59:59 UTC. Winner gets NFT. More info in "Auctions"</p>
            <button className="dina text-white hover:text-black connectBtn" id="auctionStartError"></button>
          </div>
        </div>
        <div className="col-span-2">
            
          </div>
        <div className="dina text-white tableDiv">
          <h2 className="tableTitle">Open Auctions</h2>
          {
                (openAuctions.length === 0 ? (
                  <p>No Auctions open currently</p>
                ):(
            
                    <table className="w-full mx-auto table-fixed">
                      <thead className="tableHeader">
                        <th>#</th>
                        <th>CRT Bid</th>
                        <th>CRT</th>
                        <th></th>
                        <th></th>
                      </thead>
                    
                    <tbody className="w-3/4 mx-auto">
                      {
                        openAuctions.map((prop, i) => (
                          prop.state === true ? (
                          <tr className="w-3/4 mx-auto tableRow" key={i}>
                            <td>{prop.auctionId}</td>
                            <td>{prop.bid}</td>
                            <td className="underline"><a href={prop.biderURL} target="_blank" rel="noreferrer">{prop.bider}</a></td>
                            <td ><input className="actInput rounded border boder-black border-solid text-black text-center" id={prop.btnId} placeholder="Bid"></input></td>
                            <td><button id={prop.btn2Id} className="actBtn rounded bg-green-500 text-black hover:bg-green-300" onClick={() => bid(prop.auctionId)}>Bid</button></td>
                          </tr>
                          ):(
                          <tr className="w-3/4 mx-auto tableRow" key={i}>
                            <td>{prop.auctionId}</td>
                            <td>{prop.bid}</td>
                            <td className="underline"><a href={prop.biderURL} target="_blank" rel="noreferrer">{prop.bider}</a></td>
                            <td></td>
                            <td><button id={prop.btnId} className="actBtn rounded bg-green-500 text-black hover:bg-green-300" onClick={() => closeAuction(prop.auctionId)}>Close</button></td>
                          </tr>
                          )
                        ))
                      }
                    </tbody>
                    </table>
                ))
              }
        </div>
        <div className="overflow-y-scroll dina " id="closed">
          <h2 className=" text-center pt-0 tableTitle table2">Closed Auctions</h2>
          {
                (closedAuctions.length === 0 ? (
                  <p>No Auctions closed yet</p>
                ):(
                    <table className="w-3/4 mx-auto table-fixed">
                      <thead className='text-center tableHeader'>
                        <th >#</th>
                        <th>win bid</th>
                        <th>winner</th>
                        <th>NFT</th>
                        <th>id</th>
                      </thead>
                    <tbody className="w-3/4 mx-auto">
                      {
                        closedAuctions.map((prop, i) => (
                          <tr className="w-3/4 mx-auto tableRow" key={i}>
                            <td>{prop.auctionId}</td>
                            <td>{prop.bid}</td>
                            <td className="underline"><a href={prop.biderURL} target="_blank" rel="noreferrer">{prop.bider}</a></td>
                            <td className="mx-auto text-center"><div dangerouslySetInnerHTML={{ __html: prop.nftimageSmall}} className="mx-auto text-center pt-2"></div></td>
                            <td className="mx-auto">{prop.nft}</td>
                            {/*<td><img src={`data:image/svg+xml;utf8,${prop.nftimage}`} width={50} height={50} className="rounded" alt={ prop.auctionId }/></td>
                            */}
                          </tr>
                        ))
                      }
                    </tbody>
                    </table>
                ))
            }
        </div>
      </div>
      <div className="w-1300 h-500 text-left" id="rarities_mobile">
        <h4 className="compTitle mt-4 text-left text-white">Rarity and Colors</h4>
        <div className="text-center">
          <div className="grid grid-cols-5">
            <h3 className="colorTitle text-white">Solid</h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle text-white">Qty</h3>
          </div>
          <div className="grid grid-cols-5">
            <img src={solid} className="p-1-3" alt="NineP Logo"></img>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle text-white">10</h3>
          </div>
          <div className="grid grid-cols-5">
            <h3 className="colorTitle text-white">Triple</h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle text-white">Qty</h3>
          </div>
          <div className="grid grid-cols-5">
            <img src={triple1} className="p-1-3" alt="NineP Logo"></img>
            <img src={triple2} className="p-1-3" alt="NineP Logo"></img>
            <img src={triple3} className="p-1-3" alt="NineP Logo"></img>
            <img src={triple4} className="p-1-3" alt="NineP Logo"></img>
            <h3 className="colorTitle text-white">360</h3>
          </div>
          <div className="grid grid-cols-5">
            <h3 className="colorTitle text-white">Double</h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle"></h3>
          </div>
          <div className="grid grid-cols-5">
            <h3 className="colorSubTitle text-white">&nbsp;opp. 2x2</h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle text-white">Qty</h3>
          </div>
          <div className="grid grid-cols-5">
            <img src={opp2x21} className="p-1-3" alt="NineP Logo"></img>
            <img src={opp2x22} className="p-1-3" alt="NineP Logo"></img>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle text-white">180</h3>
          </div>
          <div className="grid grid-cols-5">
            <h3 className="colorSubTitle text-white">&nbsp;near 2x2</h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle text-white">Qty</h3>
          </div>
          <div className="grid grid-cols-5">
            <img src={n2x21} className="p-1-3" alt="NineP Logo"></img>
            <img src={n2x22} className="p-1-3" alt="NineP Logo"></img>
            <img src={n2x23} className="p-1-3" alt="NineP Logo"></img>
            <img src={n2x24} className="p-1-3" alt="NineP Logo"></img>
            <h3 className="colorTitle text-white">360</h3>
          </div>
          <div className="grid grid-cols-5">
            <h3 className="colorSubTitle text-white">opp.</h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle text-white">Qty</h3>
          </div>
          <div className="grid grid-cols-5">
            <img src={opp1} className="p-1-3" alt="NineP Logo"></img>
            <img src={opp2} className="p-1-3" alt="NineP Logo"></img>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle text-white">1450</h3>
          </div>
          <div className="grid grid-cols-5">
            <h3 className="colorSubTitle text-white">near</h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle text-white">Qty</h3>
          </div>
          <div className="grid grid-cols-5">
            <img src={near1} className="p-1-3" alt="NineP Logo"></img>
            <img src={near2} className="p-1-3" alt="NineP Logo"></img>
            <img src={near3} className="p-1-3" alt="NineP Logo"></img>
            <img src={near4} className="p-1-3" alt="NineP Logo"></img>
            <h3 className="colorTitle text-white">3270</h3>
          </div>
          <div className="grid grid-cols-5">
            <h3 className="colorTitle text-white">&nbsp;Colorful</h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle text-white">Qty</h3>
          </div>
          <div className="grid grid-cols-5">
            <img src={colorful} className="p-1-3" alt="NineP Logo"></img>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle"></h3>
            <h3 className="colorTitle text-white">4370</h3>
          </div>
          <div className="opacity-0 text-left mt-12 ml-4">
            <h3 className="colorTitle text-left inline">Colors</h3>
            <svg className="inline ml-12" width="200" height="40">
              <rect x='0' y='0' width='20' height='20' style={{fill:"#ff0000"}} /> 
              <rect x='20' y='0' width='20' height='20' style={{fill:"#ff9000"}} /> 
              <rect x='40' y='0' width='20' height='20' style={{fill:"#ffff00"}} /> 
              <rect x='60' y='0' width='20' height='20' style={{fill:"#00ff00"}} /> 
              <rect x='80' y='0' width='20' height='20' style={{fill:"#00ffff"}} /> 
              <rect x='100' y='0' width='20' height='20' style={{fill:"#0080ff"}} /> 
              <rect x='120' y='0' width='20' height='20' style={{fill:"#0000ff"}} /> 
              <rect x='140' y='0' width='20' height='20' style={{fill:"#8000ff"}} /> 
              <rect x='160' y='0' width='20' height='20' style={{fill:"#ff00ff"}} /> 
              <rect x='180' y='0' width='20' height='20' style={{fill:"#ff0080"}} /> 
            </svg>
          </div>
          </div>
        

      </div>
      <div className="text-left">
      <Accordion allowZeroExpanded className="text-left w-9/10">
                <AccordionItem className="AccordionItem">
                    <AccordionItemHeading className="">
                        <AccordionItemButton>
                            <h4 className="compTitle">What is 9ineP</h4>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="">
                        <p className='compText'>
                        "9ineP" means "nine pixels" (3x3) - the tiniest original size of the flowers, so they are truly quantum units! Just 9 pixels, but enough to create something aesthetic, fundamental and jewel-like - that's why the logo looks like a jewelry box's bow.
                        "9P" - conceptual collection of 100% on-chain art, essentially limited to 10k possible variations, stored and randomly generating day by day directly on the Ethereum blockchain.<br></br><br></br>
                         Only one unique flower mints a day by the Auction, and no one knows what. So it will take around 27 years to reveal the entire collection!<br></br><br></br>
                         Each flower has permanent gold core and 9P green leaves. Petals are randomly generating every Auction (the contract uses hashing mechanism for randomness) 
                         from 10 juicy colors: red, orange, yellow, green, diamond, azure, blue, violet, magenta and pink.<br></br><br></br>
                         What flower will bloom today?<br></br>
                         What super rare flowers will appear this year?
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="AccordionItem">
                    <AccordionItemHeading className="">
                        <AccordionItemButton>
                            <h4 className="compTitle">Auctions</h4>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="">
                        <p className='compText'>
                          Who will give it a bloom today?<br></br>
                          To enable displays and enjoy the full experience - connect wallet.<br></br><br></br>
                          Only one Auction can be created a day from 00:00:01 UTC and lasts until 23:59:59 UTC. 
                          Auction is in your hands - everyone can open it with a starting bid 0.015 ETH or more. Auction Winner gets the right to randomly generate flower on-chain, mint it & reveal to the world.<br></br><br></br>
                          When the Auction time runs up - close Auction button appears (this is also a sign that a new one Auction can be created). 
                          This button generates and mints NFT for the highest bidder only! (all the processes on-chain! so switch wallet gas settings on the high to complete mint surely). Flower reveals after mint.<br></br><br></br>
                          Only 12 flowers can be randomly generated, minted and revealed beyond the Auctions: 9 will be gifted to the chosen NFT communities, 2 for the team, and 1 will be raffled on our twitter account - stay tuned! 
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="AccordionItem">
                    <AccordionItemHeading className="">
                        <AccordionItemButton>
                            <h4 className="compTitle">Treasury & Lottery</h4>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="">
                        <p className='compText'>
                         85% of the Auction proceeds goes to the treasury, 15% to the team. 
                         Treasury will be spent on the project's growth and popularity, creating aesthetic jewelry and merch, collaborating with art galleries and brands, supporting charity initiatives to restore our planet, supporting innovative technologies and talented artists..
                         all ideas and intentions will be transparent and co-creative!<br></br><br></br>
                         Also 9P flower = guaranteed pass to the Annual Lottery among the holders & your chance to win a piece of treasury. More flowers - more chances! 
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="AccordionItem">
                    <AccordionItemHeading className="">
                        <AccordionItemButton>
                            <h4 className="compTitle">Kaikasekai</h4>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="">
                        <p className='compText'>
                         "Kaikasekai" means "blooming world."<br></br><br></br>
                          This fundamental principle inspired us to create something beautiful with special mechanics to expand it. 
                          So every day, the quantum entanglement turns into 9P flower, like a symbol of "kaikasekai," to bring you flourishing and fun both in web2 and web3!
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
      </div>
      <div className="">
        &copy; 2022 kaikasekai<br></br>
      </div>

      
    </div>)
  }
}

export default App;
