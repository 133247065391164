/* general config */
export const targetChain = "1"
export const NinePContract = "0x98c2a2aAe05B62f7bFc286E0963eD280ef0Df748"

/*
export const targetChain = "1337"
export const NinePContract = "0xf5d13ED29D117f5D6cD3307a33D35a1055283d81"
*/

export const alchemyApi = "3EFz_q-xX58q2sG8mue_3ogw6gUlPLgA"
